import { defineComponent as _defineComponent } from 'vue'
import { IBuyOrder } from "@/models/RenewableOptions";
import RenewableOptionsModule from "@ems/containers/RenewableOptions/RenewableOptions.module";
import { computed, onMounted, ref, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { formatToUTCLocalMonth } from "@/utils/helpers/convertDateTime";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import GoogleMap from "@/components/GoogleMap/index.vue";
import { TypeMap } from "@/constants";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { TypeContentMarker } from "@/components/GoogleMap/ContentMarker";

export default /*@__PURE__*/_defineComponent({
  __name: 'RenewableOptionDetail',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const route = useRoute();
const { renewable_option_id }: { renewable_option_id?: string } = route.params;
const renewableOptionDetails = computed(
  () => RenewableOptionsModule.renewableOptionDetailsGetter
);

const isRenewableOptionDetails = computed(
  () => RenewableOptionsModule.isRenewableOptionDetailsGetter
);

const isLoadingDownLoad = computed(
  () => RenewableOptionsModule.isLoadingDownLoadGetter
);

onMounted(() => {
  getDataRenewableEnergy();
  TenantUserModule.getTenantProfile();
});
const center = computed(() => TenantUserModule.dataTenantProfileGetter);
const exportExcel = async () => {
  RenewableOptionsModule.setIsLoadingDownload(true);
  // eslint-disable-next-line no-undef
  const xlsx = XLSX;

  /* flatten objects */
  const rows = renewableOptionDetails?.value?.Details?.map((row: any) => ({
    SerialNumber: row.SerialNumber,
    Vintage: row.Vintage,
    Amount: row.Amount,
    Registry: row.Registry,
    Type: row.EnergyValue,
  }));

  /* generate worksheet and workbook */
  const worksheet = xlsx.utils.json_to_sheet(rows);
  const workbook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workbook, worksheet, "Dates");

  /* fix headers */
  xlsx.utils.sheet_add_aoa(
    worksheet,
    [["Serial Number", "Vintage", "Amount", "Registry", "Type"]],
    {
      origin: "A1",
    }
  );

  /* calculate column width */
  const max_width = rows.reduce(
    (w: any, r: any) => Math.max(w, r.RecId?.length),
    10
  );

  worksheet["!cols"] = [{ wch: max_width }];

  /* create an XLSX file and try to save to Presidents.xlsx */
  xlsx.writeFile(workbook, `ReportRec-${Date.now()}.xlsx`);
  RenewableOptionsModule.setIsLoadingDownload(false);
};
const getDataRenewableEnergy = () => {
  RenewableOptionsModule.getRenewableOptionDetailsAction({
    orderId: renewable_option_id as string,
  });
};

const __returned__ = { router, route, renewable_option_id, renewableOptionDetails, isRenewableOptionDetails, isLoadingDownLoad, center, exportExcel, getDataRenewableEnergy, get IBuyOrder() { return IBuyOrder }, get RenewableOptionsModule() { return RenewableOptionsModule }, computed, onMounted, ref, onUnmounted, get useRouter() { return useRouter }, get useRoute() { return useRoute }, get formatToUTCLocalMonth() { return formatToUTCLocalMonth }, BaseSvgIcon, GoogleMap, get TypeMap() { return TypeMap }, get TenantUserModule() { return TenantUserModule }, get TypeContentMarker() { return TypeContentMarker } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})